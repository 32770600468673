<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">CounterParties</a></li>
    </ol>
    <h4 class="page-header">COUNTERPARTIES</h4>
    <panel noButton="true" variant="panel-default">
      <vue-good-table
          :columns="columns"
          :lineNumbers="true"
          :pagination-options="{ enabled: true, position: 'bottom' }"
          :rows="counter_parties"
          :search-options="{ enabled: true }"
      >
        <div slot="table-actions">
          <router-link
              v-if="$can.view($store.getters.getAuthUser, 'create-counterparties')"
              :to="{ name: 'counterparties.create'}"
              class="btn btn-sm btn-black"
          >
            <i class="fa fa-plus"></i> Create New
          </router-link>
          <download-excel
              v-if="$can.view($store.getters.getAuthUser, 'create-counterparties')"
              :data="reports"
              :title="'Counterparties'"
              class="btn btn-primary"
              name="Counterparties.xls"
          >
            <i class="fa fa-file-excel"></i> Export Excel
          </download-excel>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.label === 'Action'">
            <button
                v-if="$can.view($store.getters.getAuthUser, 'edit-counterparties')"
                class="btn btn-sm btn-info"
                type="button"
                @click="edit(props.row)">
              Edit
            </button>
             <button
                 v-if="$can.view($store.getters.getAuthUser, 'delete-counterparties')"
                 class="btn btn-sm ml-1 btn-danger"
                 @click.prevent="deleteParty(props.row)"
             >
               Delete
             </button>
          </span>
        </template>
      </vue-good-table>
    </panel>
    <modal
        :show-modal="showModal"
        @close="showModal = false"
    >
      <template v-slot:header>
        <h5>Edit Counterparty</h5>
      </template>
      <form @submit.prevent="update">
        <div v-html="$error.handle(error)" />
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Name</label>
              <input
                  v-model="counter_party.name"
                  class="form-control"
                  required
                  type="text"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Physical Address</label>
              <input
                  v-model="counter_party.physical_address"
                  class="form-control"
                  required
                  type="text"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Postal Address</label>
              <input
                  v-model="counter_party.postal_address"
                  class="form-control"
                  required
                  type="text"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Category</label>
              <v-select
                  v-model="counter_party.category_secret"
                  :options="categories"
                  :reduce="x => x.secret"
                  label="name"
                  placeholder="Category"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Postal Code</label>
              <v-select
                  v-model="counter_party.postal_code_id"
                  :options="postal_codes"
                  :reduce="x => x.id"
                  label="code"
                  placeholder="Postal Code"
                  @input="getCounterparty"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Postal Name</label>
              <input
                  v-model="postal_code"
                  class="form-control"
                  readonly=""
                  required
                  type="text"
              >
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <button class="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        {
          label: "CounterParty",
          field: "name",
        },
        {
          label: "Physical Address",
          field: "physical_address"
        },
        {
          label: "Postal Address",
          field: "postal_address"
        },
        {
          label: "Action",
          field: "id"
        }
      ],
      loading: true,
      showModal: false,
      categories: [],
      postal_codes: [],
      postal_code: '',
      counter_party: {
        name: '',
        postal_code_id: null,
        category_secret: ''
      },
      error: '',
    }
  },
  computed: {
    reports() {
      return this.counter_parties.map(row => {
        return {
          'Company': row.name,
          'Physical Address': row.physical_address,
          'Postal Address': row.postal_address
        }
      });
    },
    counter_parties() {
      return this.$store.getters.getCounterParties;
    },
  },
  mounted() {
    this.fetch();
    this.getCounterparties();
  },
  methods: {
    fetch() {
      this.$axios.get('/api/v1/counterparty').then(response => {
        this.categories = response.data.data.categories;
        this.postal_codes = response.data.data.postalcodes;
        this.loading = false;
      });
    },
    getCounterparties() {
      if (!this.counter_parties.length) {
        this.$store.dispatch("fetchCounterParties").then(() => {
          this.loading = false;
        });
      }
    },
    edit(c) {
      this.postal_code = this.postal_codes.filter(x => x.id === c.postal_code_id)[0].name;
      this.counter_party = c;
      this.showModal = true;
    },
    getCounterparty() {
      if (this.counter_party.postal_code_id) {
        this.postal_code = this.postal_codes.filter(x => x.id === this.counter_party.postal_code_id)[0].name;
      }
    },
    update() {
      let data = this.counter_party;
      data.postal_code = this.counter_party.postal_code_id;
      data.category = this.counter_party.category_secret;

      this.$axios.put('/api/v1/counterparty/' + this.counter_party.id, data).then((response) => {
        this.showModal = false;
        this.$store.dispatch("setCounterParty", response.data.data);
        this.$toastr.s("Counterparty updated successfully")
      }).catch(error => {
        this.error = error.response;
      });
    },
    deleteParty(party) {
      this.$swal({
        title: 'Are you sure?',
        text: "Are you sure you want to delete the counterparty " + party.name + " ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$axios.delete('/api/v1/counterparty/' + party.id).then(() => {
            this.$store.dispatch("deleteCounterParty", party);
            this.$toastr.s("Counterparty deleted successfully");
          });
        }
      });
    }
  }
}
</script>